<template>
  <section>
    <div class="nav-element" :id="id"></div>
    <img :src="headerImage" alt="section image" class="section-header mb-5"/>
    <div class="container">
      <slot></slot>
    </div>

  </section>
</template>

<script>
export default {
  name: 'SectionView',
  props: {
    id: String,
    name: String,
    headerImage: String,
  },
  data() {
    return {
      menuItems: this.items,
    }
  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.nav-element {
  height: 75px;
  width: 100%;
}
  .section-header {
    width: 100%;
  }


  .thumbnail {
    width: 100% !important;
  }

  .section-content {
    text-align: center;
    font-size: small;

    .en-text {
      margin-top: 30px;
      display: block;
      color: #0061B5;
    }
  }



</style>
