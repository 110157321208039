<template>

  <div class="slider-container">
    <div class="slider">
      <div ref="slider" class="slides">
        <div v-for="(item,index) in sliderItems" :key="index" :id="'slides__' + index" class="slide">
          <a :href="item.link">
            <img :src="item.imgSrc" alt="slider-element" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SliderView',
  props: {
    items: Array
  },
  data() {
    return {
      sliderItems: this.shuffle(this.items),
      currentIndex: 0,
      maxIndex: this.items.length-1,
      sliderObject: undefined,
    }
  },

  methods: {
    shuffle: function (array) {
      let currentIndex = array.length,  randomIndex;
      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    },
  },
  mounted() {
    let screenWidth = window.innerWidth;
    this.sliderObject = this.$refs.slider;
    setInterval( ()=>{
      if (this.currentIndex < this.maxIndex) {
        this.sliderObject.scrollBy(screenWidth, 0);
        this.currentIndex += 1;
      } else {
        this.sliderObject.scrollBy(this.maxIndex * (-1) * screenWidth, 0);
        this.currentIndex = 0;
      }
    }, 4500 );
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 75px;

  .slider {
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden !important;

    .slides {
      display: flex;
      overflow-x: hidden;
      position: relative;
      scroll-behavior: smooth;
      scroll-snap-type: x mandatory;


      .slide:nth-of-type(even) {
        background-color: rgb(250, 246, 212);
      }

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        margin-right: 0px;
        box-sizing: border-box;
        background: white;
        transform-origin: center center;
        transform: scale(1);
        scroll-snap-align: center;


        img {
          width: 100%;
        }

      }



    }

  }

}


</style>
